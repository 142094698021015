import React, { useEffect } from 'react';
import { Navigate, useParams, useNavigate } from 'react-router-dom';

// 檢測是否在服務器端環境
const isServer = typeof window === 'undefined';

/**
 * 舊路徑重定向組件
 * 根據用戶的語言偏好智能重定向到新的路徑格式
 */
const OldPathRedirect = ({ getNewPath, validLanguages, defaultLang }) => {
  const params = useParams();
  const navigate = useNavigate();
  
  useEffect(() => {
    // 在服務器端不執行此 effect
    if (isServer) return;
    
    // 1. 首先檢查 localStorage 中是否有保存的語言偏好
    let userLang = defaultLang;
    
    try {
      const savedLang = localStorage.getItem('i18nextLng');
      userLang = savedLang && validLanguages.includes(savedLang) 
        ? savedLang 
        : defaultLang;
    } catch (e) {
      console.error('Error accessing localStorage:', e);
    }
    
    // 2. 使用提供的函數生成新路徑
    const newPath = getNewPath(params, userLang);
    
    // 3. 使用 navigate 進行重定向
    navigate(newPath, { replace: true });
  }, [params, getNewPath, validLanguages, defaultLang, navigate]);
  
  // 在服務器端渲染時，返回一個靜態的 Navigate 組件
  // 這確保了服務器端和客戶端的初始渲染結果一致
  return <Navigate to={getNewPath(params, defaultLang)} replace />;
};

export default OldPathRedirect;
