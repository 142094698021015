import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const VideoCardSkeleton = () => {
  return (
    <div className="video-card">
      <div>
        <div className="video-thumbnail">
          <Skeleton height={180} width="100%" />
        </div>
        <div className="video-info">
          <h3 className="video-title">
            <Skeleton width="85%" />
          </h3>
          <div className="video-meta">
            <p className="mb-1">
              <Skeleton width="60%" />
            </p>
            <p className="mb-1 text-sm">
              <Skeleton width="40%" />
            </p>
            <p className="text-sm text-gray-500">
              <Skeleton width="50%" />
            </p>
          </div>
          <div className="mt-2 flex flex-wrap">
            <Skeleton width={60} height={24} style={{ marginRight: '8px', borderRadius: '9999px' }} />
            <Skeleton width={80} height={24} style={{ marginRight: '8px', borderRadius: '9999px' }} />
            <Skeleton width={70} height={24} style={{ borderRadius: '9999px' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCardSkeleton;
