import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { localizedLink, getLanguageVersionPath } from '../../utils/linkHelper';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentYear = new Date().getFullYear();
  const currentLanguage = i18n.language || 'tw';
  
  // 切換語言
  const changeLanguage = (lng) => {
    // 導航到新語言的相同頁面
    const newPath = getLanguageVersionPath(location.pathname, lng);
    navigate(newPath);
    
    // 滾動到頁面頂部，增強用戶體驗
    window.scrollTo(0, 0);
  };
  
  return (
    <footer className="bg-secondary-900 text-white py-8 border-t-4 border-gold-600">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* 網站信息 */}
          <div>
            <h3 className="text-xl font-bold mb-4">{t('common.appName')}</h3>
            <p className="text-primary-300 mb-4 flex items-center">
              <span className="text-gold-500 mr-1">©</span>
              {currentYear} {t('common.appName')}. {t('footer.allRightsReserved')}
            </p>
            {/* 社交平台圖標已隱藏 */}
          </div>
          
          {/* 快速鏈接 */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-gold-500">{t('footer.quickLinks')}</h3>
            <ul className="space-y-2">
              <li>
                <Link to={localizedLink('', currentLanguage)} className="text-primary-300 hover:text-gold-400">
                  {t('nav.home')}
                </Link>
              </li>
              <li>
                <Link to={localizedLink('category', currentLanguage)} className="text-primary-300 hover:text-gold-400">
                  {t('nav.categories')}
                </Link>
              </li>
              <li>
                <Link to={localizedLink('category', currentLanguage)} className="text-primary-300 hover:text-gold-400">
                  {t('nav.latest')}
                </Link>
              </li>
              {/* 熱門影片已隱藏 */}
            </ul>
          </div>
          
          {/* 語言選擇 */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-gold-500">{t('nav.language')}</h3>
            <ul className="space-y-2">
              <li>
                <button 
                  onClick={() => changeLanguage('tw')}
                  className="text-primary-300 hover:text-gold-400"
                >
                  {t('languages.tw')}
                </button>
              </li>
              <li>
                <button 
                  onClick={() => changeLanguage('cn')}
                  className="text-primary-300 hover:text-gold-400"
                >
                  {t('languages.cn')}
                </button>
              </li>
              <li>
                <button 
                  onClick={() => changeLanguage('en')}
                  className="text-primary-300 hover:text-gold-400"
                >
                  {t('languages.en')}
                </button>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="mt-8 pt-8 border-t border-secondary-800 text-center text-sm">
          <p className="text-gold-400 mb-2">{t('footer.disclaimer')}</p>
          <p className="text-primary-300">{t('footer.adultContent')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
