/**
 * SEO服務
 * 
 * 這個服務負責生成所有頁面的SEO數據，確保無論是SSG還是SPA模式，
 * 都使用相同的邏輯生成SEO標籤，從而保證標籤的一致性和唯一性。
 * 
 * 更新：現在支持從seo-config.json配置文件中讀取SEO數據，
 * 這樣可以更容易地為每個頁面設置不同的SEO標籤，而不需要複雜的SSG部署。
 */

import seoConfig from '../seo-config.json';

/**
 * 根據語言獲取基本關鍵詞
 * @param {string} lang - 語言代碼 (tw, cn, en)
 * @returns {string} - 基本關鍵詞
 */
export function getBaseKeywords(lang) {
  if (lang === 'tw') {
    return '日本AV,免費影片,線上觀看,高清影片,繁體中文';
  } else if (lang === 'cn') {
    return '日本AV,免费影片,在线观看,高清影片,简体中文';
  } else {
    return 'Japanese AV,free videos,online viewing,HD videos,English';
  }
}

/**
 * 生成視頻頁面的SEO數據
 * @param {Object} params - 參數
 * @returns {Object} - SEO數據
 */
export function generateVideoSEO({ pageId, lang, baseKeywords, siteName, domain, data }) {
  // 根據是否有視頻數據生成不同的標題
  let title;
  
  if (data && (data.title || data.barcode || data.actors)) {
    // 如果有視頻數據，使用更豐富的標題格式
    const barcode = data.barcode ? `${data.barcode} ` : '';
    const videoTitle = data.title || pageId;
    
    // 處理演員信息
    let actorsStr = '';
    if (data.actors) {
      const actors = Array.isArray(data.actors) ? data.actors : 
                    typeof data.actors === 'string' && data.actors.startsWith('[') ? 
                    JSON.parse(data.actors) : 
                    [data.actors];
      
      actorsStr = actors.length > 0 ? ` | ${actors.join(', ')}` : '';
    }
    
    title = `${barcode}${videoTitle}${actorsStr} | 免費線上看 - ${siteName}`;
  } else {
    // 如果沒有視頻數據或數據不完整，確保至少有影片ID
    title = `${pageId} | 免費線上看 - ${siteName}`;
  }
  
  // 描述和關鍵詞
  const description = data && data.description 
    ? `${data.title || pageId} - ${data.description.substring(0, 150)}...` 
    : `${pageId} - 高清免費線上觀看，無需註冊，提供多種分類和標籤`;
  
  const keywords = data && data.tags 
    ? `${pageId},${data.tags.split(',').map(tag => tag.trim()).join(',')},${baseKeywords}`
    : `${pageId},${baseKeywords}`;
  
  const ogType = 'video.other';
  const url = `${domain}/${lang}/video/${pageId}`;
  
  // 豐富SEO數據
  let thumbnailUrl = data && data.thumbnail ? data.thumbnail : '';
  let uploadDate = data && data.production_date ? data.production_date : new Date().toISOString();
  
  // 增強的視頻結構化數據
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": data && data.title ? data.title : pageId,
    "description": data && data.description ? data.description : `${pageId} - 高清免費線上觀看`,
    "thumbnailUrl": thumbnailUrl,
    "uploadDate": uploadDate,
    "contentUrl": url,
    "duration": data && data.duration ? `PT${Math.floor(data.duration / 60)}M${data.duration % 60}S` : "PT0M0S",
    "embedUrl": url,
    "interactionStatistic": {
      "@type": "InteractionCounter",
      "interactionType": "http://schema.org/WatchAction",
      "userInteractionCount": data && data.views ? data.views : 0
    }
  };
  
  // 如果有演員信息，添加到結構化數據中
  if (data && data.actors) {
    const actors = Array.isArray(data.actors) ? data.actors : 
                  typeof data.actors === 'string' && data.actors.startsWith('[') ? 
                  JSON.parse(data.actors) : 
                  [data.actors];
    
    if (actors.length > 0) {
      jsonLd.actor = actors.map(actor => ({
        "@type": "Person",
        "name": actor
      }));
    }
  }
  
  return {
    title,
    description,
    keywords,
    ogType,
    url,
    pageType: 'video',
    pageId,
    jsonLd
  };
}

/**
 * 生成分類頁面的SEO數據
 * @param {Object} params - 參數
 * @returns {Object} - SEO數據
 */
export function generateCategorySEO({ pageId, subType, subValue, lang, baseKeywords, siteName, domain }) {
  let title, description, keywords;
  const ogType = "website";
  let url = `${domain}/${lang}/category`;
  
  if (subType && subValue) {
    // 特定分類頁面 (標籤、片商、演員)
    const categoryType = subType === 'tag' ? '標籤' : 
                        subType === 'manufacturer' ? '片商' : 
                        subType === 'actor' ? '演員' : '分類';
    
    const decodedValue = decodeURIComponent(subValue);
    title = `${decodedValue} | ${categoryType}影片 - ${siteName}`;
    description = `瀏覽 ${decodedValue} ${categoryType}的所有影片，高清免費線上觀看，無需註冊`;
    keywords = `${decodedValue},${categoryType},${baseKeywords}`;
    url = `${domain}/${lang}/category/${subType}/${subValue}`;
  } else if (pageId) {
    // 其他分類頁面
    title = `${pageId} | 分類影片 - ${siteName}`;
    description = `瀏覽 ${pageId} 分類的所有影片，高清免費線上觀看，無需註冊`;
    keywords = `${pageId},分類,${baseKeywords}`;
    url = `${domain}/${lang}/category/${pageId}`;
  } else {
    // 所有分類頁面
    title = `所有分類 | ${siteName}`;
    description = `瀏覽所有影片分類，找到您喜愛的內容，高清免費線上觀看，無需註冊`;
    keywords = `分類,標籤,片商,演員,${baseKeywords}`;
  }
  
  // 分類頁結構化數據
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "name": title.split(' | ')[0],
    "description": description,
    "url": url
  };
  
  return {
    title,
    description,
    keywords,
    ogType,
    url,
    pageType: 'category',
    pageId,
    subType,
    subValue,
    jsonLd
  };
}

/**
 * 生成404頁面的SEO數據
 * @param {Object} params - 參數
 * @returns {Object} - SEO數據
 */
export function generate404SEO({ lang, baseKeywords, siteName, domain }) {
  const title = `頁面未找到 | ${siteName}`;
  const description = `您請求的頁面不存在，請返回首頁或嘗試其他內容`;
  const keywords = baseKeywords;
  const ogType = "website";
  const url = `${domain}/${lang}/404`;
  
  return {
    title,
    description,
    keywords,
    ogType,
    url,
    pageType: '404',
    pageId: '',
    jsonLd: null
  };
}

/**
 * 生成首頁的SEO數據
 * @param {Object} params - 參數
 * @returns {Object} - SEO數據
 */
export function generateHomeSEO({ lang, baseKeywords, siteName, domain }) {
  let title, description;
  
  if (lang === 'tw') {
    title = `免費日本AV線上看 | 高清中文字幕 | ${siteName}`;
    description = `提供高質量影片內容，包括多種分類和標籤。免費線上日本AV影片觀看平台。`;
  } else if (lang === 'cn') {
    title = `免费日本AV在线看 | 高清中文字幕 | ${siteName}`;
    description = `提供高质量影片内容，包括多种分类和标签。免费在线日本AV影片观看平台。`;
  } else {
    title = `Free Japanese AV Online | HD Videos | ${siteName}`;
    description = `Provides high-quality video content with various categories and tags. Free online Japanese AV video viewing platform.`;
  }
  
  const keywords = baseKeywords;
  const ogType = "website";
  const url = `${domain}/${lang}`;
  
  // 首頁結構化數據
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": siteName,
    "url": url,
    "potentialAction": {
      "@type": "SearchAction",
      "target": `${domain}/${lang}/category/search/{search_term_string}`,
      "query-input": "required name=search_term_string"
    }
  };
  
  return {
    title,
    description,
    keywords,
    ogType,
    url,
    pageType: 'home',
    pageId: '',
    jsonLd
  };
}

/**
 * 根據URL參數生成SEO數據
 * @param {Object} params - 參數
 * @returns {Object} - SEO數據
 */
export function generateSEO(params) {
  const { url, lang, pageType, pageId, subType, subValue, data } = params;
  
  // 網站名稱和域名
  const siteName = 'JavPlay.tv';
  const domain = 'https://javplay.tv';
  
  // 嘗試從配置文件中獲取SEO數據
  let seoData = null;
  
  if (seoConfig[lang] && seoConfig[lang][pageType]) {
    if (pageId && seoConfig[lang][pageType]._template) {
      // 使用模板處理動態頁面
      const template = seoConfig[lang][pageType]._template;
      
      // 獲取視頻標題、代碼等信息
      let videoTitle = pageId;
      let videoCode = pageId;
      
      if (data) {
        videoTitle = data.title || pageId;
        videoCode = data.barcode || pageId;
      }
      
      // 替換模板中的變數
      seoData = {
        title: template.title
          .replace(/{videoId}/g, pageId)
          .replace(/{videoTitle}/g, videoTitle)
          .replace(/{videoCode}/g, videoCode),
        description: template.description
          .replace(/{videoId}/g, pageId)
          .replace(/{videoTitle}/g, videoTitle)
          .replace(/{videoCode}/g, videoCode),
        keywords: template.keywords
          .replace(/{videoId}/g, pageId)
          .replace(/{videoTitle}/g, videoTitle)
          .replace(/{videoCode}/g, videoCode),
        ogType: template.ogType,
        url: `${domain}/${lang}/${pageType}/${pageId}`,
        pageType,
        pageId
      };
    } else if (!pageId && seoConfig[lang][pageType]) {
      // 使用靜態配置
      const config = seoConfig[lang][pageType];
      seoData = {
        title: config.title,
        description: config.description,
        keywords: config.keywords,
        ogType: config.ogType,
        url: `${domain}/${lang}${pageType === 'home' ? '' : '/' + pageType}`,
        pageType,
        pageId: ''
      };
    }
  }
  
  // 如果配置文件中沒有對應的數據，使用原來的動態生成方式
  if (!seoData) {
    // 根據語言設置基本關鍵詞
    const baseKeywords = getBaseKeywords(lang);
    
    // 根據頁面類型生成SEO數據
    if (pageType === 'video' && pageId) {
      return generateVideoSEO({ pageId, lang, baseKeywords, siteName, domain, data });
    } else if (pageType === 'category') {
      return generateCategorySEO({ pageId, subType, subValue, lang, baseKeywords, siteName, domain });
    } else if (pageType === '404') {
      return generate404SEO({ lang, baseKeywords, siteName, domain });
    } else {
      return generateHomeSEO({ lang, baseKeywords, siteName, domain });
    }
  }
  
  // 生成結構化數據
  if (pageType === 'home') {
    seoData.jsonLd = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": siteName,
      "url": seoData.url,
      "potentialAction": {
        "@type": "SearchAction",
        "target": `${domain}/${lang}/category/search/{search_term_string}`,
        "query-input": "required name=search_term_string"
      }
    };
  } else if (pageType === 'video' && pageId) {
    // 視頻結構化數據
    let thumbnailUrl = '';
    let uploadDate = new Date().toISOString();
    
    if (data) {
      thumbnailUrl = data.thumbnail_large || data.thumbnail_small || '';
      uploadDate = data.upload_date || new Date().toISOString();
    }
    
    seoData.jsonLd = {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": pageId,
      "description": seoData.description,
      "thumbnailUrl": thumbnailUrl,
      "uploadDate": uploadDate,
      "contentUrl": seoData.url
    };
  } else if (pageType === 'category') {
    // 分類頁結構化數據
    seoData.jsonLd = {
      "@context": "https://schema.org",
      "@type": "CollectionPage",
      "name": seoData.title.split(' | ')[0],
      "description": seoData.description,
      "url": seoData.url
    };
  }
  
  return seoData;
}

/**
 * 創建Helmet對象
 * @param {Object} seoData - SEO數據
 * @returns {Object} - Helmet對象
 */
/**
 * 創建Helmet對象，用於SSR/SSG渲染
 * @param {Object} seoData - SEO數據
 * @returns {Object} - Helmet對象
 */
export function createHelmetFromSEO(seoData) {
  if (!seoData) return null;
  
  // 確保所有必要的字段都存在
  const safeData = {
    title: seoData.title || 'JavPlay.tv - 免費線上日本AV影片觀看平台',
    description: seoData.description || '提供高清日本成人影片免費觀看，最新、最熱門的AV作品，無需註冊',
    keywords: seoData.keywords || '日本AV,免費AV,線上AV,高清AV,無碼AV,有碼AV,JavPlay,日本成人影片,AV女優',
    ogType: seoData.ogType || 'website',
    url: seoData.url || 'https://javplay.tv',
    jsonLd: seoData.jsonLd || null
  };
  
  return {
    title: {
      toString: () => `<title>${safeData.title}</title>`
    },
    meta: {
      toString: () => `
        <meta name="description" content="${safeData.description}" />
        <meta name="keywords" content="${safeData.keywords}" />
        <meta property="og:title" content="${safeData.title}" />
        <meta property="og:description" content="${safeData.description}" />
        <meta property="og:type" content="${safeData.ogType}" />
        <meta property="og:url" content="${safeData.url}" />
        <meta property="og:site_name" content="JavPlay.tv" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="${safeData.title}" />
        <meta name="twitter:description" content="${safeData.description}" />
      `
    },
    link: {
      toString: () => {
        try {
          // 從URL中提取路徑部分，不包括語言前綴
          const urlWithoutLang = safeData.url.replace(/^https?:\/\/[^\/]+\/[a-z]{2}/, '');
          const baseUrl = safeData.url.replace(/\/[a-z]{2}\/.*$/, '');
          
          // 構建不同語言版本的URL
          const twUrl = `${baseUrl}/tw${urlWithoutLang}`;
          const cnUrl = `${baseUrl}/cn${urlWithoutLang}`;
          const enUrl = `${baseUrl}/en${urlWithoutLang}`;
          
          return `
            <link rel="canonical" href="${safeData.url}" />
            <link rel="alternate" hreflang="zh-TW" href="${twUrl}" />
            <link rel="alternate" hreflang="zh-CN" href="${cnUrl}" />
            <link rel="alternate" hreflang="en" href="${enUrl}" />
            <link rel="alternate" hreflang="x-default" href="${twUrl}" />
          `;
        } catch (error) {
          console.error('Error generating language links:', error);
          return `<link rel="canonical" href="${safeData.url}" />`;
        }
      }
    },
    script: {
      toString: () => {
        if (!safeData.jsonLd) return '';
        
        try {
          return `<script type="application/ld+json">${JSON.stringify(safeData.jsonLd)}</script>`;
        } catch (error) {
          console.error('Error generating JSON-LD:', error);
          return '';
        }
      }
    }
  };
}
