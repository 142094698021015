import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import VideoCardSkeleton from './VideoCardSkeleton';

const HomeSkeleton = () => {
  return (
    <div>
      {/* 最新上傳影片骨架 */}
      <section className="mb-12">
        <div className="flex justify-between items-center mb-6">
          <Skeleton height={32} width={200} />
          <Skeleton width={100} />
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {Array(8).fill(0).map((_, index) => (
            <VideoCardSkeleton key={index} />
          ))}
        </div>
      </section>
      
      {/* 分類瀏覽骨架 */}
      <section className="mb-12">
        <div className="flex justify-between items-center mb-6">
          <Skeleton height={32} width={200} />
          <Skeleton width={100} />
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex flex-wrap gap-3">
            {Array(20).fill(0).map((_, index) => (
              <Skeleton 
                key={index}
                width={80 + Math.random() * 60} 
                height={36} 
                style={{ borderRadius: '9999px' }} 
              />
            ))}
          </div>
        </div>
      </section>
      
      {/* 片商瀏覽骨架 */}
      <section className="mb-12">
        <div className="flex justify-between items-center mb-6">
          <Skeleton height={32} width={200} />
          <Skeleton width={100} />
        </div>
        
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
          {Array(12).fill(0).map((_, index) => (
            <div key={index} className="p-4 bg-white shadow-md rounded-lg">
              <Skeleton height={24} width="80%" style={{ margin: '0 auto' }} />
              <Skeleton height={16} width="60%" style={{ margin: '8px auto 0' }} />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default HomeSkeleton;
