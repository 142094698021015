import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { getOrigin } from '../../utils/serverUtils';

const NotFound = () => {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const currentLanguage = lang || i18n.language || 'tw';
  
  return (
    <div className="flex flex-col items-center justify-center py-16">
      {/* 添加 Helmet 組件來管理頭部元數據 */}
      <Helmet>
        <title>{`${t('common.pageNotFound')} | ${t('common.appName')}`}</title>
        <meta name="description" content={t('common.pageNotFound')} />
        <meta name="robots" content="noindex, nofollow" />
        
        {/* 多語言替代連結 */}
        <link rel="alternate" hreflang="zh-TW" href={`${getOrigin()}/tw/404`} />
        <link rel="alternate" hreflang="zh-CN" href={`${getOrigin()}/cn/404`} />
        <link rel="alternate" hreflang="en" href={`${getOrigin()}/en/404`} />
        <link rel="alternate" hreflang="x-default" href={`${getOrigin()}/tw/404`} />
        
        {/* 404頁面結構化數據 */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": `${t('common.pageNotFound')} | ${t('common.appName')}`,
            "description": t('common.pageNotFound'),
            "url": `${getOrigin()}/${currentLanguage}/404`,
            "inLanguage": currentLanguage === 'tw' ? 'zh-TW' : currentLanguage === 'cn' ? 'zh-CN' : 'en',
            "isPartOf": {
              "@type": "WebSite",
              "name": t('common.appName'),
              "url": `${getOrigin()}/${currentLanguage}`
            },
            "speakable": {
              "@type": "SpeakableSpecification",
              "cssSelector": ["h1", "h2", "p"]
            },
            "breadcrumb": {
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": t('common.appName'),
                  "item": `${getOrigin()}/${currentLanguage}`
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": t('common.pageNotFound'),
                  "item": `${getOrigin()}/${currentLanguage}/404`
                }
              ]
            }
          })}
        </script>
      </Helmet>
      <h1 className="text-6xl font-bold text-primary-600 mb-4">404</h1>
      <h2 className="text-2xl font-semibold mb-6">{t('common.pageNotFound')}</h2>
      <p className="text-gray-600 mb-8 text-center max-w-md">
        您嘗試訪問的頁面不存在或已被移除。
      </p>
  <Link 
    to={`/${currentLanguage}`} 
    className="px-6 py-3 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition-colors"
  >
        {t('common.back')} {t('nav.home')}
      </Link>
    </div>
  );
};

export default NotFound;
