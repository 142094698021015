import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import VideoCardSkeleton from './VideoCardSkeleton';

const VideoDetailSkeleton = () => {
  return (
    <div>
      {/* 影片播放器區域骨架 */}
      <section className="mb-8">
        <Skeleton height={450} width="100%" />
      </section>
      
      {/* 影片詳細信息骨架 */}
      <section className="mb-12 bg-white p-6 rounded-lg shadow-md">
        <Skeleton height={32} width="80%" className="mb-4" />
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <p className="mb-2">
              <Skeleton width="70%" />
            </p>
            <p className="mb-2">
              <Skeleton width="60%" />
            </p>
            <p className="mb-2">
              <Skeleton width="50%" />
            </p>
          </div>
          
          <div>
            <p className="mb-2">
              <Skeleton width="75%" />
            </p>
          </div>
        </div>
        
        {/* 標籤骨架 */}
        <div className="mt-4">
          <p className="font-semibold mb-2">
            <Skeleton width={100} />
          </p>
          <div className="flex flex-wrap gap-2">
            {Array(6).fill(0).map((_, index) => (
              <Skeleton 
                key={index}
                width={60 + Math.random() * 40} 
                height={28} 
                style={{ borderRadius: '9999px' }} 
              />
            ))}
          </div>
        </div>
        
        {/* 完整版觀看連結骨架 */}
        <div className="mt-6">
          <Skeleton height={50} width="100%" />
        </div>
        
        {/* 描述骨架 */}
        <div className="mt-6">
          <p className="font-semibold mb-2">
            <Skeleton width={120} />
          </p>
          <Skeleton count={4} />
        </div>
      </section>
      
      {/* 相關影片骨架 */}
      <section className="mb-8">
        <Skeleton height={32} width="40%" className="mb-6" />
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {Array(3).fill(0).map((_, index) => (
            <VideoCardSkeleton key={index} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default VideoDetailSkeleton;
