import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { generateSEO } from '../../services/seoService';
import { videoApi } from '../../api/api';

// 檢測是否在服務器端環境
const isServer = typeof window === 'undefined';

/**
 * SEOHandler 組件
 * 
 * 這個組件負責處理SEO標籤，確保在客戶端導航時也能使用正確的SEO標籤。
 * 它會從 window.__INITIAL_SEO_DATA__ 中讀取預渲染時注入的SEO數據，
 * 並使用 Helmet 組件將這些數據應用到頁面中。
 * 
 * 對於客戶端導航，它會根據當前路徑動態生成SEO標籤。
 * 
 * 注意：即使SSG過程失敗，基本的SEO標籤也會通過index.html中的硬編碼標籤提供，
 * 確保搜索引擎始終能看到關鍵SEO信息。
 */
const SEOHandler = () => {
  const location = useLocation();
  const [seoData, setSeoData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  // 在服務器端渲染時不做任何事情，因為SEO標籤已經由SSG處理
  if (isServer) return null;
  
  useEffect(() => {
    try {
      // 從全局變量中獲取初始SEO數據
      const initialSeoData = window.__INITIAL_SEO_DATA__ || {};
      
      // 解析當前URL路徑
      const urlParts = location.pathname.split('/').filter(Boolean);
      const lang = urlParts[0] || 'tw';
      const pageType = urlParts[1] || 'home';
      const pageId = urlParts[2] || '';
      const subType = urlParts[3] || '';
      const subValue = urlParts[4] || '';
      
      // 網站域名
      const domain = 'https://javplay.tv';
      
      // 如果當前路徑與初始SEO數據的路徑相同，直接使用初始數據
      if (initialSeoData.url === `${domain}${location.pathname}`) {
        setSeoData(initialSeoData);
        setIsLoading(false);
        return;
      }
      
      // 否則，根據當前路徑生成新的SEO數據
      const fetchData = async () => {
        setIsLoading(true);
        
        // 獲取頁面數據（如果需要）
        let pageData = null;
        if (pageType === 'video' && pageId) {
          try {
            // 嘗試獲取視頻詳情數據
            pageData = await videoApi.getVideoDetail(pageId).catch(err => {
              console.error(`Error fetching video detail for ${pageId}:`, err);
              return null;
            });
          } catch (error) {
            console.error(`Error fetching video detail for ${pageId}:`, error);
          }
        }
        
        try {
          // 使用SEO服務生成SEO數據
          const newSeoData = generateSEO({
            url: location.pathname,
            lang,
            pageType,
            pageId,
            subType,
            subValue,
            data: pageData
          });
          
          setSeoData(newSeoData);
        } catch (error) {
          console.error('Error generating SEO data:', error);
          // 使用備用SEO數據
          setSeoData({
            title: `JavPlay.tv - 免費線上日本AV影片觀看平台${pageId ? ` - ${pageId}` : ''}`,
            description: '提供高清日本成人影片免費觀看，最新、最熱門的AV作品，無需註冊',
            keywords: '日本AV,免費AV,線上AV,高清AV,無碼AV,有碼AV,JavPlay,日本成人影片,AV女優',
            ogType: pageType === 'video' ? 'video.other' : 'website',
            url: `${domain}${location.pathname}`,
            pageType,
            pageId
          });
        }
        
        setIsLoading(false);
      };
      
      fetchData();
    } catch (error) {
      console.error('SEO處理錯誤:', error);
      // 使用備用SEO數據
      setSeoData({
        title: 'JavPlay.tv - 免費線上日本AV影片觀看平台',
        description: '提供高清日本成人影片免費觀看，最新、最熱門的AV作品，無需註冊',
        keywords: '日本AV,免費AV,線上AV,高清AV,無碼AV,有碼AV,JavPlay,日本成人影片,AV女優',
        ogType: 'website',
        url: 'https://javplay.tv',
        pageType: 'home',
        pageId: ''
      });
      setIsLoading(false);
    }
  }, [location.pathname]);
  
  // 如果沒有SEO數據或正在加載，不做任何事情
  if (isLoading || !seoData || !seoData.title) return null;
  
  return (
    <Helmet>
      {/* 
        對於視頻頁面，我們不在客戶端設置標題，以避免與SSR/SSG生成的標題衝突
        這確保爬蟲始終看到預渲染的完整標題
      */}
      {seoData.pageType !== 'video' && <title>{seoData.title}</title>}
      
      {/* 基本元數據 */}
      {seoData.description && <meta name="description" content={seoData.description} />}
      {seoData.keywords && <meta name="keywords" content={seoData.keywords} />}
      
      {/* Open Graph 標籤 */}
      <meta property="og:title" content={seoData.title} />
      {seoData.description && <meta property="og:description" content={seoData.description} />}
      <meta property="og:type" content={seoData.ogType || 'website'} />
      {seoData.url && <meta property="og:url" content={seoData.url} />}
      <meta property="og:site_name" content="JavPlay.tv" />
      
      {/* Twitter 卡片 */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seoData.title} />
      {seoData.description && <meta name="twitter:description" content={seoData.description} />}
      
      {/* 多語言支持 */}
      <link rel="canonical" href={seoData.url} />
      <link rel="alternate" hreflang="zh-TW" href={seoData.url.replace(/\/(tw|cn|en)\//, '/tw/')} />
      <link rel="alternate" hreflang="zh-CN" href={seoData.url.replace(/\/(tw|cn|en)\//, '/cn/')} />
      <link rel="alternate" hreflang="en" href={seoData.url.replace(/\/(tw|cn|en)\//, '/en/')} />
      <link rel="alternate" hreflang="x-default" href={seoData.url.replace(/\/(tw|cn|en)\//, '/tw/')} />
      
      {/* 結構化數據 */}
      {seoData.jsonLd && (
        <script type="application/ld+json">
          {JSON.stringify(seoData.jsonLd)}
        </script>
      )}
    </Helmet>
  );
};

export default SEOHandler;
