import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { localizedLink } from '../../utils/linkHelper';
import { videoApi } from '../../api/api';
import VideoCard from '../../components/VideoCard';
import VideoCardSkeleton from '../../components/SkeletonComponents/VideoCardSkeleton';
import Loading from '../../components/Loading';
import { getOrigin, reloadPage } from '../../utils/serverUtils';

// 檢測是否在服務器端環境
const isServer = typeof window === 'undefined';

const Home = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language || 'tw';
  const [latestVideos, setLatestVideos] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [tags, setTags] = useState([]);
  
  // 為每個區塊設置獨立的載入狀態
  const [latestVideosLoading, setLatestVideosLoading] = useState(true);
  const [manufacturersLoading, setManufacturersLoading] = useState(true);
  const [tagsLoading, setTagsLoading] = useState(true);
  
  // 為每個區塊設置獨立的錯誤狀態
  const [latestVideosError, setLatestVideosError] = useState(null);
  const [manufacturersError, setManufacturersError] = useState(null);
  const [tagsError, setTagsError] = useState(null);
  
  // 檢查是否有預渲染的數據
  const hasPrerenderedData = () => {
    if (isServer) return false;
    
    try {
      // 檢查是否有 window.__INITIAL_DATA__ 全局變量
      return typeof window !== 'undefined' && 
             window.__INITIAL_DATA__ && 
             window.__INITIAL_DATA__.latestVideos;
    } catch (e) {
      console.error('Error checking prerendered data:', e);
      return false;
    }
  };
  
  useEffect(() => {
    // 如果有預渲染的數據，直接使用
    if (hasPrerenderedData()) {
      try {
        const prerenderedData = window.__INITIAL_DATA__;
        
        if (prerenderedData.latestVideos) {
          setLatestVideos(prerenderedData.latestVideos);
          setLatestVideosLoading(false);
        }
        
        if (prerenderedData.manufacturers) {
          setManufacturers(prerenderedData.manufacturers.slice(0, 12));
          setManufacturersLoading(false);
        }
        
        if (prerenderedData.tags) {
          setTags(prerenderedData.tags.slice(0, 20));
          setTagsLoading(false);
        }
        
        // 在後台重新獲取數據，以確保數據是最新的
        fetchAllData(true);
        return;
      } catch (e) {
        console.error('Error using prerendered data:', e);
        // 如果使用預渲染數據出錯，則正常獲取數據
      }
    }
    
    // 獲取所有數據
    fetchAllData(false);
  }, [t]);
  
  // 獲取所有數據的函數
  const fetchAllData = async (isBackgroundFetch = false) => {
    // 獲取最新影片
    const fetchLatestVideos = async () => {
      try {
        if (!isBackgroundFetch) setLatestVideosLoading(true);
        const latestData = await videoApi.getLatestVideos(8);
        setLatestVideos(latestData);
        setLatestVideosLoading(false);
      } catch (err) {
        console.error('Error fetching latest videos:', err);
        setLatestVideosError(t('common.error'));
        setLatestVideosLoading(false);
      }
    };
    
    // 獲取所有片商
    const fetchManufacturers = async () => {
      try {
        if (!isBackgroundFetch) setManufacturersLoading(true);
        const manufacturersData = await videoApi.getManufacturers();
        setManufacturers(manufacturersData.slice(0, 12));
        setManufacturersLoading(false);
      } catch (err) {
        console.error('Error fetching manufacturers:', err);
        setManufacturersError(t('common.error'));
        setManufacturersLoading(false);
      }
    };
    
    // 獲取所有標籤
    const fetchTags = async () => {
      try {
        if (!isBackgroundFetch) setTagsLoading(true);
        const tagsData = await videoApi.getTags();
        setTags(tagsData.slice(0, 20));
        setTagsLoading(false);
      } catch (err) {
        console.error('Error fetching tags:', err);
        setTagsError(t('common.error'));
        setTagsLoading(false);
      }
    };
    
    // 同時發起所有請求，但各自獨立處理
    fetchLatestVideos();
    fetchManufacturers();
    fetchTags();
  };
  
  // 檢查是否所有區塊都出錯
  const allErrors = latestVideosError && manufacturersError && tagsError;
  
  if (allErrors) {
    return (
      <div className="text-center py-16">
        <p className="text-red-500 mb-4">{t('common.error')}</p>
        <button 
          onClick={() => reloadPage()}
          className="px-4 py-2 bg-gold-600 text-white rounded-md hover:bg-gold-700"
        >
          {t('common.retry')}
        </button>
      </div>
    );
  }
  
  // 不再使用整體的 Loading 組件，而是在每個區塊內部處理載入狀態
  return (
    <div>
      {/* 添加 Helmet 組件來管理頭部元數據 */}
      <Helmet>
        <title>{`免費日本AV線上看 | 高清中文字幕 | ${t('common.appName')}`}</title>
        <meta name="description" content={t('home.description', '提供高質量影片內容，包括多種分類和標籤。')} />
        <link rel="canonical" href={`${getOrigin()}/${currentLanguage}`} />
        
        {/* Open Graph 標籤 - 用於社交媒體分享 */}
        <meta property="og:title" content={`免費日本AV線上看 | 高清中文字幕 | ${t('common.appName')}`} />
        <meta property="og:description" content={t('home.description', '提供高質量影片內容，包括多種分類和標籤。')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${getOrigin()}/${currentLanguage}`} />
        
        {/* Twitter 卡片 */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t('common.appName')} />
        <meta name="twitter:description" content={t('home.description', '提供高質量影片內容，包括多種分類和標籤。')} />
        
        {/* 多語言替代連結 */}
        <link rel="alternate" hreflang="zh-TW" href={`${getOrigin()}/tw`} />
        <link rel="alternate" hreflang="zh-CN" href={`${getOrigin()}/cn`} />
        <link rel="alternate" hreflang="en" href={`${getOrigin()}/en`} />
        <link rel="alternate" hreflang="x-default" href={`${getOrigin()}/tw`} />
        
        {/* 語系特定的元數據 */}
        {currentLanguage === 'tw' && (
          <>
            <meta name="description" content="提供高質量影片內容，包括多種分類和標籤。免費線上日本AV影片觀看平台。" />
            <meta name="keywords" content="日本AV,免費影片,線上觀看,高清影片,繁體中文" />
          </>
        )}
        {currentLanguage === 'cn' && (
          <>
            <meta name="description" content="提供高质量影片内容，包括多种分类和标签。免费在线日本AV影片观看平台。" />
            <meta name="keywords" content="日本AV,免费影片,在线观看,高清影片,简体中文" />
          </>
        )}
        {currentLanguage === 'en' && (
          <>
            <meta name="description" content="Provides high-quality video content with various categories and tags. Free online Japanese AV video viewing platform." />
            <meta name="keywords" content="Japanese AV,free videos,online viewing,HD videos,English" />
          </>
        )}
        
        {/* 結構化數據 - JSON-LD 格式 - 增強版 */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": t('common.appName'),
            "url": `${getOrigin()}/${currentLanguage}`,
            "description": t('home.description', '提供高質量影片內容，包括多種分類和標籤。'),
            "inLanguage": currentLanguage === 'tw' ? 'zh-TW' : currentLanguage === 'cn' ? 'zh-CN' : 'en',
            "potentialAction": {
              "@type": "SearchAction",
              "target": `${getOrigin()}/${currentLanguage}/category/search/{search_term_string}`,
              "query-input": "required name=search_term_string"
            },
            "publisher": {
              "@type": "Organization",
              "name": t('common.appName'),
              "logo": {
                "@type": "ImageObject",
                "url": `${getOrigin()}/favicon.ico`
              }
            },
            "sameAs": [
              `${getOrigin()}/tw`,
              `${getOrigin()}/cn`,
              `${getOrigin()}/en`
            ]
          })}
        </script>
        
        {/* 組織結構化數據 */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": t('common.appName'),
            "url": `${getOrigin()}`,
            "logo": {
              "@type": "ImageObject",
              "url": `${getOrigin()}/favicon.ico`
            }
          })}
        </script>
      </Helmet>
      {/* 最新上傳影片 - 根據載入狀態顯示骨架屏或實際內容 */}
      <section className="mb-12">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">{t('home.latestVideos')}</h2>
          <Link to={localizedLink('category', currentLanguage)} className="text-gold-600 hover:underline">
            {t('common.viewMore')}
          </Link>
        </div>
        
        {latestVideosLoading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {Array(8).fill(0).map((_, index) => (
              <VideoCardSkeleton key={index} />
            ))}
          </div>
        ) : latestVideosError ? (
          <div className="text-center py-8">
            <p className="text-red-500 mb-4">{latestVideosError}</p>
            <button 
              onClick={() => reloadPage()}
              className="px-4 py-2 bg-gold-600 text-white rounded-md hover:bg-gold-700"
            >
              {t('common.retry')}
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {latestVideos.map((video, index) => (
              <VideoCard 
                key={video.hid} 
                video={video} 
                priority={index < 2} // 前兩張圖片設為高優先級
              />
            ))}
          </div>
        )}
      </section>
      
      
      {/* 分類瀏覽 - 根據載入狀態顯示骨架屏或實際內容 */}
      <section className="mb-12">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">{t('home.browseByCategory')}</h2>
          <Link to={localizedLink('category', currentLanguage)} className="text-gold-600 hover:underline">
            {t('common.viewMore')}
          </Link>
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow-md">
          {tagsLoading ? (
            <div className="flex flex-wrap gap-3">
              {Array(20).fill(0).map((_, index) => (
                <div 
                  key={index}
                  className="px-4 py-2 bg-gray-200 rounded-full animate-pulse"
                  style={{ width: `${80 + Math.random() * 60}px`, height: '36px' }}
                ></div>
              ))}
            </div>
          ) : tagsError ? (
            <div className="text-center py-8">
              <p className="text-red-500 mb-4">{tagsError}</p>
              <button 
                onClick={() => reloadPage()}
                className="px-4 py-2 bg-gold-600 text-white rounded-md hover:bg-gold-700"
              >
                {t('common.retry')}
              </button>
            </div>
          ) : (
            <div className="flex flex-wrap gap-3">
              {(() => {
                // 標準化標籤並合併相同標籤的計數
                const normalizedTagMap = new Map();
                
                tags.forEach(tag => {
                  const tagName = tag.tag.toLowerCase().trim();
                  
                  // 過濾掉不需要的標籤
                  if (tagName.includes('av女優片') || 
                      tagName.includes('av女优片') || 
                      tagName.includes('av actress') || 
                      tagName.includes('720p') || 
                      tagName.includes('hd') || 
                      tagName.includes('4k')) {
                    return;
                  }
                  
                  if (normalizedTagMap.has(tagName)) {
                    const existingTag = normalizedTagMap.get(tagName);
                    existingTag.video_count += tag.video_count;
                  } else {
                    normalizedTagMap.set(tagName, {
                      tag: tagName,
                      video_count: tag.video_count
                    });
                  }
                });
                
                // 轉換回數組並按計數排序
                return Array.from(normalizedTagMap.values())
                  .sort((a, b) => b.video_count - a.video_count)
                  .map((tag, index) => (
                    <Link 
                      key={index}
                      to={localizedLink(`category/tag/${encodeURIComponent(tag.tag)}`, currentLanguage)}
                      className="px-4 py-2 bg-gradient-to-r from-primary-100 to-primary-200 hover:from-primary-200 hover:to-primary-300 border border-gold-400 rounded-full text-secondary-900 transition-colors duration-200 shadow-sm"
                    >
                      {tag.tag} <span className="text-gold-600 font-medium">({tag.video_count})</span>
                    </Link>
                  ));
              })()}
            </div>
          )}
        </div>
      </section>
      
      {/* 片商瀏覽 - 根據載入狀態顯示骨架屏或實際內容 */}
      <section className="mb-12">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">{t('home.browseByManufacturer')}</h2>
          <Link to={localizedLink('category', currentLanguage)} className="text-gold-600 hover:underline">
            {t('common.viewMore')}
          </Link>
        </div>
        
        {manufacturersLoading ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
            {Array(12).fill(0).map((_, index) => (
              <div key={index} className="p-4 bg-white shadow-md rounded-lg animate-pulse">
                <div className="h-6 bg-gray-200 rounded w-4/5 mx-auto"></div>
                <div className="h-4 bg-gray-200 rounded w-3/5 mx-auto mt-2"></div>
              </div>
            ))}
          </div>
        ) : manufacturersError ? (
          <div className="text-center py-8">
            <p className="text-red-500 mb-4">{manufacturersError}</p>
            <button 
              onClick={() => reloadPage()}
              className="px-4 py-2 bg-gold-600 text-white rounded-md hover:bg-gold-700"
            >
              {t('common.retry')}
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
            {manufacturers.map((manufacturer, index) => (
              <Link 
                key={index}
                to={localizedLink(`category/manufacturer/${encodeURIComponent(manufacturer.manufacturer)}`, currentLanguage)}
                className="p-4 bg-white shadow-md rounded-lg hover:shadow-lg transition-shadow text-center border border-gold-400 hover:border-gold-600"
              >
                <div className="font-medium">{manufacturer.manufacturer}</div>
                <div className="text-sm text-gold-600">
                  {manufacturer.video_count} 部影片
                </div>
              </Link>
            ))}
          </div>
        )}
      </section>
    </div>
  );
};

export default Home;
