// 客戶端入口文件
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import './i18n';
import 'react-loading-skeleton/dist/skeleton.css';
import { HelmetProvider } from 'react-helmet-async';

// 等待 DOM 完全加載
window.addEventListener('DOMContentLoaded', () => {
  // 使用 React 18 的 API
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  );
});
