import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

// 檢測是否在服務器端環境
const isServer = typeof window === 'undefined';

/**
 * 智能默認語言重定向組件
 * 根據用戶的語言偏好或瀏覽器設置選擇最合適的語言
 */
const DefaultLanguageRedirect = ({ validLanguages, defaultLang }) => {
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    // 在服務器端不執行此 effect
    if (isServer) return;
    
    let targetLang = defaultLang;
    
    // 1. 首先檢查 localStorage 中是否有保存的語言偏好
    try {
      const savedLang = localStorage.getItem('i18nextLng');
      if (savedLang && validLanguages.includes(savedLang)) {
        targetLang = savedLang;
      } else {
        // 2. 檢查瀏覽器語言設置
        const browserLang = navigator.language.toLowerCase();
        
        // 處理中文變體
        if (browserLang.startsWith('zh')) {
          if (browserLang.includes('tw') || browserLang.includes('hk')) {
            targetLang = 'tw';
          } else if (browserLang.includes('cn')) {
            targetLang = 'cn';
          } else {
            // 其他中文變體默認使用繁體
            targetLang = 'tw';
          }
        } 
        // 處理英文
        else if (browserLang.startsWith('en')) {
          targetLang = 'en';
        }
      }
    } catch (e) {
      console.error('Error detecting language:', e);
    }
    
    // 檢查當前路徑是否是無效路徑（不是根路徑）
    const isInvalidPath = location.pathname !== '/';
    
    // 使用 navigate 進行重定向
    if (isInvalidPath) {
      console.log(`檢測到無效路徑: ${location.pathname}，重定向到 /${targetLang}/404`);
      navigate(`/${targetLang}/404`, { 
        replace: true, 
        state: { from: location.pathname } 
      });
    } else {
      navigate(`/${targetLang}`, { replace: true });
    }
  }, [location, navigate, validLanguages, defaultLang]);
  
  // 在服務器端渲染時，返回一個靜態的 Navigate 組件
  // 這確保了服務器端和客戶端的初始渲染結果一致
  return <Navigate to={`/${defaultLang}`} replace />;
};

export default DefaultLanguageRedirect;
