import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import VideoCardSkeleton from './VideoCardSkeleton';

const CategorySkeleton = () => {
  return (
    <div>
      {/* 頁面標題骨架 */}
      <Skeleton height={32} width={300} className="mb-6" />
      
      {/* 篩選和排序骨架 */}
      <div className="mb-8 flex flex-wrap gap-4 items-center">
        <div className="flex items-center">
          <Skeleton width={60} className="mr-2" />
          <Skeleton width={120} height={40} />
        </div>
        
        <Skeleton width={150} />
      </div>
      
      {/* 影片列表骨架 */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-8">
        {Array(8).fill(0).map((_, index) => (
          <VideoCardSkeleton key={index} />
        ))}
      </div>
      
      {/* 加載更多按鈕骨架 */}
      <div className="text-center mt-8">
        <Skeleton width={120} height={40} style={{ margin: '0 auto' }} />
      </div>
    </div>
  );
};

export default CategorySkeleton;
