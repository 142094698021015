/**
 * 生成帶有語言前綴的連結
 * @param {string} path - 不包含語言前綴的路徑
 * @param {string} language - 語言代碼 (tw, cn, en)
 * @returns {string} 帶有語言前綴的完整路徑
 */
export const localizedLink = (path, language) => {
  // 確保路徑不以 / 開頭
  const cleanPath = path.startsWith('/') ? path.substring(1) : path;
  return `/${language}/${cleanPath}`;
};

/**
 * 從當前路徑中移除語言前綴
 * @param {string} path - 當前路徑
 * @returns {string} 不包含語言前綴的路徑
 */
export const removeLanguagePrefix = (path) => {
  return path.replace(/^\/(tw|cn|en)/, '');
};

/**
 * 獲取當前路徑的不同語言版本
 * @param {string} currentPath - 當前路徑
 * @param {string} targetLanguage - 目標語言代碼
 * @returns {string} 目標語言版本的路徑
 */
export const getLanguageVersionPath = (currentPath, targetLanguage) => {
  const pathWithoutLang = removeLanguagePrefix(currentPath);
  return `/${targetLanguage}${pathWithoutLang}`;
};
