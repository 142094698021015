import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { localizedLink } from '../../utils/linkHelper';
import { videoApi } from '../../api/api';
import VideoPlayer from '../../components/VideoPlayer';
import VideoCard from '../../components/VideoCard';
import VideoCardSkeleton from '../../components/SkeletonComponents/VideoCardSkeleton';
import Loading from '../../components/Loading';
import { getOrigin, scrollToTop, reloadPage } from '../../utils/serverUtils';

const VideoDetail = () => {
  const { t, i18n } = useTranslation();
  const { hid: id } = useParams(); // 將參數重命名為更通用的 id
  const location = useLocation();
  const currentLanguage = i18n.language || 'tw';
  const [video, setVideo] = useState(null);
  const [relatedVideos, setRelatedVideos] = useState([]);
  
  // 為每個區塊設置獨立的載入狀態
  const [videoLoading, setVideoLoading] = useState(true);
  const [relatedVideosLoading, setRelatedVideosLoading] = useState(true);
  
  // 為每個區塊設置獨立的錯誤狀態
  const [videoError, setVideoError] = useState(null);
  const [relatedVideosError, setRelatedVideosError] = useState(null);
  
  const [loadingMore, setLoadingMore] = useState(false);
  
  // 隨機打亂數組的函數（Fisher-Yates 洗牌算法）
  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };
  
  // 當組件掛載或 ID 變化時滾動到頁面頂部
  useEffect(() => {
    // 滾動到頁面頂部，增強用戶體驗
    scrollToTop();
  }, [id]);
  
  useEffect(() => {
    // 獲取影片詳情
    const fetchVideoDetail = async () => {
      try {
        setVideoLoading(true);
        const data = await videoApi.getVideoDetail(id);
        
        // 移除後端返回的相關影片，確保不會使用它們
        const { related_videos, ...videoData } = data;
        setVideo(videoData);
        setVideoLoading(false);
      } catch (err) {
        console.error('Error fetching video detail:', err);
        setVideoError(t('common.error'));
        setVideoLoading(false);
      }
    };
    
    // 獲取相關影片
    const fetchRelatedVideos = async () => {
      try {
        setRelatedVideosLoading(true);
        
        // 獲取相關影片（基於演員）
        let relatedVideosList = [];
        
        // 首先需要獲取當前影片的信息，以便獲取演員信息
        const videoData = await videoApi.getVideoDetail(id);
        
        // 獲取當前影片的 hid 和 barcode
        const currentId = String(id);
        const currentBarcode = videoData.barcode ? String(videoData.barcode) : null;
        
        // 檢查影片是否為當前影片的函數
        const isCurrentVideo = (video) => {
          const videoHid = String(video.hid);
          const videoBarcode = video.barcode ? String(video.barcode) : null;
          
          // 如果 hid 相同，或者 barcode 相同且不為 null，則認為是當前影片
          return videoHid === currentId || 
                 (currentBarcode && videoBarcode && currentBarcode === videoBarcode);
        };
        
        if (videoData && videoData.actors) {
          try {
            const actors = formatActors(videoData.actors);
            if (actors.length > 0) {
              // 使用第一個演員獲取相關影片
              const actorVideos = await videoApi.getVideosByActor(actors[0], { limit: 6 });
              // 過濾掉當前影片（檢查 hid 和 barcode）
              const filteredVideos = actorVideos.videos.filter(v => !isCurrentVideo(v));
              relatedVideosList = filteredVideos.slice(0, 3);
            }
          } catch (relatedErr) {
            console.error('Error fetching related videos by actor:', relatedErr);
          }
        }
        
        // 如果相關影片不足三部，隨機推薦其他影片
        if (relatedVideosList.length < 3) {
          try {
            // 獲取隨機影片（設置較大的limit以確保有足夠的影片可供選擇）
            const randomVideos = await videoApi.getVideos({ limit: 20 });
            
            // 過濾掉當前影片和已有的相關影片
            const existingHids = new Set(relatedVideosList.map(v => String(v.hid)));
            const filteredRandomVideos = randomVideos.videos.filter(v => {
              // 如果是當前影片，排除
              if (isCurrentVideo(v)) return false;
              
              // 如果已經在相關影片列表中，排除
              return !existingHids.has(String(v.hid));
            });
            
            // 隨機打亂結果
            const shuffledVideos = shuffleArray(filteredRandomVideos);
            
            // 補充不足的影片數量
            const neededCount = 3 - relatedVideosList.length;
            const additionalVideos = shuffledVideos.slice(0, neededCount);
            
            // 合併結果
            relatedVideosList = [...relatedVideosList, ...additionalVideos];
          } catch (randomErr) {
            console.error('Error fetching random videos:', randomErr);
          }
        }
        
        // 確保設置的相關影片不包含當前影片（最後一次檢查）
        const finalRelatedVideos = relatedVideosList.filter(v => !isCurrentVideo(v));
        setRelatedVideos(finalRelatedVideos);
        setRelatedVideosLoading(false);
      } catch (err) {
        console.error('Error fetching related videos:', err);
        setRelatedVideosError(t('common.error'));
        setRelatedVideosLoading(false);
      }
    };
    
    // 同時發起所有請求，但各自獨立處理
    fetchVideoDetail();
    fetchRelatedVideos();
  }, [id, t]);
  
  // 格式化日期
  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  
  // 格式化標籤為字符串，用於 meta keywords
  const formatTagsForKeywords = (tagsString) => {
    const tags = formatTags(tagsString);
    return tags.join(', ');
  };
  
  // 獲取簡短描述用於 meta description
  const getShortDescription = (description) => {
    if (!description) return '';
    // 截取前 160 個字符作為描述
    return description.substring(0, 160).trim() + (description.length > 160 ? '...' : '');
  };
  
  // 格式化標籤
  const formatTags = (tagsString) => {
    if (!tagsString) return [];
    
    // 直接按逗號分割，並修剪每個標籤
    let tags = tagsString.split(',').map(tag => tag.trim()).filter(Boolean);
    
    // 排除特定標籤
    tags = tags.filter(tag => {
      const lowerTag = tag.toLowerCase();
      return !lowerTag.includes('av女優片') && 
             !lowerTag.includes('av女优片') &&
             !lowerTag.includes('av actress') &&
             !lowerTag.includes('720p') &&
             !lowerTag.includes('hd') &&
             !lowerTag.includes('4k');
    });
    
    return tags;
  };
  
  // 格式化演員
  const formatActors = (actorsString) => {
    if (!actorsString) return [];
    
    // 如果已經是陣列，直接返回
    if (Array.isArray(actorsString)) {
      return actorsString;
    }
    
    // 檢查是否是 JSON 字符串格式（以 [ 開頭和 ] 結尾）
    if (typeof actorsString === 'string' && actorsString.trim().startsWith('[') && actorsString.trim().endsWith(']')) {
      try {
        const actors = JSON.parse(actorsString);
        return Array.isArray(actors) ? actors : [actorsString];
      } catch (e) {
        // JSON 解析失敗，視為普通字符串
        return [actorsString];
      }
    }
    
    // 如果是普通字符串，直接返回包含該字符串的陣列
    return [actorsString];
  };
  
  // 處理完整版視頻URL
  const getFullVersionUrl = (videoUrl) => {
    if (!videoUrl) return '';
    
    // 對於繁體中文和簡體中文，將域名從jav188.net更改為av18.live
    if (currentLanguage === 'tw' || currentLanguage === 'cn') {
      return videoUrl.replace('jav188.net', 'av18.live');
    }
    
    // 對於英文，不需要處理
    return videoUrl;
  };
  
  // 檢查影片詳情是否出錯或未找到
  if (videoError || videoLoading === false && !video) {
    return (
      <div className="text-center py-16">
        <Helmet>
          <title>{t('common.error')} - {t('common.appName')}</title>
          <meta name="description" content={t('common.noResults')} />
        </Helmet>
        <p className="text-red-500 mb-4">{videoError || t('common.noResults')}</p>
        <Link 
          to={localizedLink('', currentLanguage)}
          className="px-4 py-2 bg-primary-600 text-white rounded-md"
        >
          {t('common.back')} {t('nav.home')}
        </Link>
      </div>
    );
  }
  
  return (
    <div>
      {/* 添加 Helmet 組件來管理頭部元數據 */}
      {/* 
        注意：標題已由SSR/SSG處理，客戶端不再設置標題，
        以避免爬蟲看到不一致的標題。只設置其他元數據。
      */}
      <Helmet>
        {/* 移除客戶端標題設置，使用服務器端預渲染的標題 */}
        <meta name="description" content={getShortDescription(video?.description) || `${video?.title || ''} - ${t('video.detail')}`} />
        <meta name="keywords" content={formatTagsForKeywords(video?.tags)} />
        <link rel="canonical" href={`${getOrigin()}/${currentLanguage}/video/${id}`} />
        
        {/* Open Graph 標籤 - 用於社交媒體分享 */}
        <meta property="og:title" content={`${video?.barcode ? `${video.barcode} ` : ''}${video?.title || ''} | ${video?.actors ? formatActors(video.actors).join(', ') : ''} | 免費線上看`} />
        <meta property="og:description" content={getShortDescription(video?.description)} />
        <meta property="og:type" content="video.other" />
        <meta property="og:url" content={`${getOrigin()}/${currentLanguage}/video/${id}`} />
        {video?.thumbnail && <meta property="og:image" content={video.thumbnail} />}
        
        {/* Twitter 卡片 */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={video?.title || ''} />
        <meta name="twitter:description" content={getShortDescription(video?.description)} />
        {video?.thumbnail && <meta name="twitter:image" content={video.thumbnail} />}
        
        {/* 多語言替代連結 */}
        <link rel="alternate" hreflang="zh-TW" href={`${getOrigin()}/tw/video/${id}`} />
        <link rel="alternate" hreflang="zh-CN" href={`${getOrigin()}/cn/video/${id}`} />
        <link rel="alternate" hreflang="en" href={`${getOrigin()}/en/video/${id}`} />
        <link rel="alternate" hreflang="x-default" href={`${getOrigin()}/tw/video/${id}`} />
        
        {/* 語系特定的元數據 */}
        {currentLanguage === 'tw' && (
          <>
            <meta name="description" content={getShortDescription(video?.description) || `${video?.title || ''} - 免費線上觀看高清日本AV影片`} />
            <meta name="keywords" content={`${formatTagsForKeywords(video?.tags)},日本AV,免費影片,線上觀看,繁體中文`} />
          </>
        )}
        {currentLanguage === 'cn' && (
          <>
            <meta name="description" content={getShortDescription(video?.description) || `${video?.title || ''} - 免费在线观看高清日本AV影片`} />
            <meta name="keywords" content={`${formatTagsForKeywords(video?.tags)},日本AV,免费影片,在线观看,简体中文`} />
          </>
        )}
        {currentLanguage === 'en' && (
          <>
            <meta name="description" content={getShortDescription(video?.description) || `${video?.title || ''} - Watch free HD Japanese AV videos online`} />
            <meta name="keywords" content={`${formatTagsForKeywords(video?.tags)},Japanese AV,free videos,online viewing,English`} />
          </>
        )}
        
        {/* 結構化數據 - JSON-LD 格式 - 增強版 */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": video?.title || id,
            "description": video?.description || `${id} - 高清免費線上觀看`,
            "thumbnailUrl": video?.thumbnail || '',
            "uploadDate": (video?.production_date) || new Date().toISOString(),
            "contentUrl": `${getOrigin()}/${currentLanguage}/video/${id}`,
            "embedUrl": `${getOrigin()}/${currentLanguage}/video/${id}`,
            "duration": video?.duration ? `PT${Math.floor(video?.duration / 60)}M${video?.duration % 60}S` : "PT0M0S",
            "isFamilyFriendly": "false",
            ...(video?.actors && {
              "actor": formatActors(video.actors).map(actor => ({
                "@type": "Person",
                "name": actor
              }))
            }),
            ...(video?.manufacturer && {
              "productionCompany": {
                "@type": "Organization",
                "name": video.manufacturer
              }
            }),
            ...(video?.barcode && {
              "identifier": video.barcode
            }),
            "potentialAction": {
              "@type": "WatchAction",
              "target": `${getOrigin()}/${currentLanguage}/video/${id}`
            }
          })}
        </script>
      </Helmet>
      {/* 影片播放器和基本信息 - 根據載入狀態顯示骨架屏或實際內容 */}
      <section className="mb-8">
        {videoLoading ? (
          <div className="w-full h-[450px] bg-gray-200 animate-pulse rounded"></div>
        ) : (
          <VideoPlayer video={video} />
        )}
        
        {/* 完整版觀看連結 - 移動到影片下方 */}
        {!videoLoading && video?.video_url && (
          <div className="mt-4 max-w-4xl mx-auto">
            <a 
              href={getFullVersionUrl(video.video_url)} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="block py-3 px-4 bg-red-600 text-white text-center rounded-md font-bold transition-all hover:bg-red-700 hover:scale-105 text-lg"
            >
              <i className="fas fa-play-circle mr-2"></i> {t('video.watchFullVersion')}
            </a>
          </div>
        )}
      </section>
      
      {/* 影片詳細信息 - 根據載入狀態顯示骨架屏或實際內容 */}
      <section className="mb-12 bg-white p-6 rounded-lg shadow-md">
        {videoLoading ? (
          <>
            <div className="h-8 bg-gray-200 w-4/5 mb-4 animate-pulse rounded"></div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <div className="h-6 bg-gray-200 w-3/4 mb-2 animate-pulse rounded"></div>
                <div className="h-6 bg-gray-200 w-2/3 mb-2 animate-pulse rounded"></div>
                <div className="h-6 bg-gray-200 w-1/2 mb-2 animate-pulse rounded"></div>
              </div>
              
              <div>
                <div className="h-6 bg-gray-200 w-3/4 mb-2 animate-pulse rounded"></div>
                <div className="h-6 bg-gray-200 w-2/3 mb-2 animate-pulse rounded"></div>
              </div>
            </div>
            
            <div className="mt-4">
              <div className="h-6 bg-gray-200 w-24 mb-2 animate-pulse rounded"></div>
              <div className="flex flex-wrap gap-2">
                {Array(6).fill(0).map((_, index) => (
                  <div 
                    key={index}
                    className="h-7 bg-gray-200 rounded-full animate-pulse"
                    style={{ width: `${60 + Math.random() * 40}px` }}
                  ></div>
                ))}
              </div>
            </div>
            
            <div className="mt-6">
              <div className="h-12 bg-gray-200 w-full animate-pulse rounded"></div>
            </div>
            
            <div className="mt-6">
              <div className="h-6 bg-gray-200 w-32 mb-2 animate-pulse rounded"></div>
              <div className="h-4 bg-gray-200 w-full mb-2 animate-pulse rounded"></div>
              <div className="h-4 bg-gray-200 w-full mb-2 animate-pulse rounded"></div>
              <div className="h-4 bg-gray-200 w-full mb-2 animate-pulse rounded"></div>
              <div className="h-4 bg-gray-200 w-3/4 animate-pulse rounded"></div>
            </div>
          </>
        ) : (
          <>
            <h1 className="text-2xl font-bold mb-4">{video.title}</h1>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                {video.manufacturer && (
                  <p className="mb-2">
                    <span className="font-semibold">{t('video.manufacturer')}:</span> 
                    <Link 
                      to={localizedLink(`category/manufacturer/${encodeURIComponent(video.manufacturer)}`, currentLanguage)}
                      className="ml-2 text-primary-600 hover:underline"
                    >
                      {video.manufacturer}
                    </Link>
                  </p>
                )}
                
                {/* 上傳日期已移除 */}
                
                {video.production_date && (
                  <p className="mb-2">
                    <span className="font-semibold">{t('video.releaseDate')}:</span> 
                    <span className="ml-2">{formatDate(video.production_date)}</span>
                  </p>
                )}
                
                {video.views !== undefined && (
                  <p className="mb-2">
                    <span className="font-semibold">{t('video.views')}:</span> 
                    <span className="ml-2">{video.views.toLocaleString()}</span>
                  </p>
                )}
              </div>
              
              <div>
                {video.barcode && (
                  <p className="mb-2">
                    <span className="font-semibold">{t('video.code')}:</span> 
                    <span className="ml-2">{video.barcode}</span>
                  </p>
                )}
                
                {/* 來源已移除 */}
                
                {video.actors && (
                  <p className="mb-2">
                    <span className="font-semibold">{t('video.actors')}:</span> 
                    <span className="ml-2">
                      {formatActors(video.actors).map((actor, index) => (
                        <React.Fragment key={actor}>
                          <Link 
                            to={localizedLink(`category/actor/${encodeURIComponent(actor)}`, currentLanguage)}
                            className="text-primary-600 hover:underline"
                          >
                            {actor}
                          </Link>
                          {index < formatActors(video.actors).length - 1 ? ', ' : ''}
                        </React.Fragment>
                      ))}
                    </span>
                  </p>
                )}
              </div>
            </div>
            
            {/* 標籤 */}
            {video.tags && (
              <div className="mt-4">
                <p className="font-semibold mb-2">{t('video.tags')}:</p>
                <div className="flex flex-wrap gap-2">
                  {formatTags(video.tags).map((tag, index) => (
                    <Link 
                      key={index}
                      to={localizedLink(`category/tag/${encodeURIComponent(tag)}`, currentLanguage)}
                      className="tag"
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
              </div>
            )}
            
            {/* 完整版觀看連結已移至影片下方 */}
            
            {/* 描述 */}
            {video.description && (
              <div className="mt-6">
                <p className="font-semibold mb-2">{t('video.description')}:</p>
                <p className="text-gray-700 whitespace-pre-line">{video.description}</p>
              </div>
            )}
          </>
        )}
      </section>
      
      {/* 相關影片 - 根據載入狀態顯示骨架屏或實際內容 */}
      <section className="mb-8">
        <h2 className="text-xl font-bold mb-6">{t('video.relatedVideos')}</h2>
        
        {relatedVideosLoading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {Array(3).fill(0).map((_, index) => (
              <VideoCardSkeleton key={index} />
            ))}
          </div>
        ) : relatedVideosError ? (
          <div className="text-center py-8">
            <p className="text-red-500 mb-4">{relatedVideosError}</p>
            <button 
              onClick={() => reloadPage()}
              className="px-4 py-2 bg-gold-600 text-white rounded-md hover:bg-gold-700"
            >
              {t('common.retry')}
            </button>
          </div>
        ) : relatedVideos.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {relatedVideos.map((relatedVideo) => (
              <VideoCard key={relatedVideo.hid} video={relatedVideo} />
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500">{t('video.noRelatedVideos')}</p>
        )}
      </section>
    </div>
  );
};

export default VideoDetail;
