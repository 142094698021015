import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';

// 導入重定向組件（僅用於客戶端）
import DefaultLanguageRedirect from './components/Redirects/DefaultLanguageRedirect';
import OldPathRedirect from './components/Redirects/OldPathRedirect';

// 直接導入頁面組件，不使用懶加載
import Home from './pages/Home';
import VideoDetail from './pages/VideoDetail';
import Category from './pages/Category';
import NotFound from './pages/NotFound';

// 布局組件
import Layout from './components/Layout';
import Loading from './components/Loading';
import LanguageWrapper from './components/LanguageWrapper';

// 檢測是否在服務器端環境
const isServer = typeof window === 'undefined';

// 客戶端重定向處理組件
const ClientSideRedirectHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const validLanguages = ['tw', 'cn', 'en'];
  const defaultLang = 'tw';
  
  useEffect(() => {
    // 處理根路徑重定向
    if (location.pathname === '/') {
      // 檢查 localStorage 中是否有保存的語言偏好
      let savedLang = defaultLang;
      
      try {
        const storedLang = localStorage.getItem('i18nextLng');
        savedLang = validLanguages.includes(storedLang) ? storedLang : defaultLang;
      } catch (e) {
        console.error('Error accessing localStorage:', e);
      }
      
      // 使用 navigate 進行重定向
      navigate(`/${savedLang}`, { replace: true });
      return;
    }
    
    // 處理舊路徑重定向
    const oldPathPatterns = [
      { pattern: /^\/video\/(.+)$/, getNewPath: (match, lang) => `/${lang}/video/${match[1]}` },
      { pattern: /^\/category$/, getNewPath: (_, lang) => `/${lang}/category` },
      { pattern: /^\/category\/tag\/(.+)$/, getNewPath: (match, lang) => `/${lang}/category/tag/${match[1]}` },
      { pattern: /^\/category\/manufacturer\/(.+)$/, getNewPath: (match, lang) => `/${lang}/category/manufacturer/${match[1]}` },
      { pattern: /^\/category\/actor\/(.+)$/, getNewPath: (match, lang) => `/${lang}/category/actor/${match[1]}` },
      { pattern: /^\/category\/search\/(.+)$/, getNewPath: (match, lang) => `/${lang}/category/search/${match[1]}` }
    ];
    
    for (const { pattern, getNewPath } of oldPathPatterns) {
      const match = location.pathname.match(pattern);
      if (match) {
        // 檢查 localStorage 中是否有保存的語言偏好
        let userLang = defaultLang;
        
        try {
          const savedLang = localStorage.getItem('i18nextLng');
          userLang = savedLang && validLanguages.includes(savedLang) 
            ? savedLang 
            : defaultLang;
        } catch (e) {
          console.error('Error accessing localStorage:', e);
        }
        
        // 使用 navigate 進行重定向
        navigate(getNewPath(match, userLang), { replace: true });
        return;
      }
    }
  }, [location, navigate]);
  
  return null;
};

function App() {
  const { t } = useTranslation();
  
  return (
    <HelmetProvider>
      {/* 客戶端重定向處理 */}
      {!isServer && <ClientSideRedirectHandler />}
      
      <Routes>
        {/* 在服務器端渲染時，不渲染重定向路由 */}
        {!isServer && (
          <>
            {/* 默認語言重定向 - 使用用戶偏好或瀏覽器語言 */}
            <Route path="/" element={
              <DefaultLanguageRedirect validLanguages={['tw', 'cn', 'en']} defaultLang="tw" />
            } />
            
            {/* 處理不帶語言前綴的無效路徑 */}
            <Route path="*" element={
              <DefaultLanguageRedirect validLanguages={['tw', 'cn', 'en']} defaultLang="tw" />
            } />
            
            {/* 舊路徑重定向 - 使用用戶偏好語言而不是硬編碼 tw */}
            <Route path="video/:hid" element={
              <OldPathRedirect 
                getNewPath={(params, lang) => `/${lang}/video/${params.hid}`} 
                validLanguages={['tw', 'cn', 'en']} 
                defaultLang="tw"
              />
            } />
            <Route path="category" element={
              <OldPathRedirect 
                getNewPath={(_, lang) => `/${lang}/category`} 
                validLanguages={['tw', 'cn', 'en']} 
                defaultLang="tw"
              />
            } />
            <Route path="category/tag/:tag" element={
              <OldPathRedirect 
                getNewPath={(params, lang) => `/${lang}/category/tag/${params.tag}`} 
                validLanguages={['tw', 'cn', 'en']} 
                defaultLang="tw"
              />
            } />
            <Route path="category/manufacturer/:manufacturer" element={
              <OldPathRedirect 
                getNewPath={(params, lang) => `/${lang}/category/manufacturer/${params.manufacturer}`} 
                validLanguages={['tw', 'cn', 'en']} 
                defaultLang="tw"
              />
            } />
            <Route path="category/actor/:actor" element={
              <OldPathRedirect 
                getNewPath={(params, lang) => `/${lang}/category/actor/${params.actor}`} 
                validLanguages={['tw', 'cn', 'en']} 
                defaultLang="tw"
              />
            } />
            <Route path="category/search/:query" element={
              <OldPathRedirect 
                getNewPath={(params, lang) => `/${lang}/category/search/${params.query}`} 
                validLanguages={['tw', 'cn', 'en']} 
                defaultLang="tw"
              />
            } />
          </>
        )}
        
        {/* 語言特定路由 - 在服務器端和客戶端都渲染 */}
        <Route path="/:lang" element={<LanguageWrapper />}>
          <Route index element={<Home />} />
          <Route path="video/:hid" element={<VideoDetail />} />
          <Route path="category">
            <Route index element={<Category />} />
            <Route path="tag/:tag" element={<Category type="tag" />} />
            <Route path="manufacturer/:manufacturer" element={<Category type="manufacturer" />} />
            <Route path="actor/:actor" element={<Category type="actor" />} />
            <Route path="search/:query" element={<Category type="search" />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </HelmetProvider>
  );
}

export default App;
