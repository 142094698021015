/**
 * 服務器端渲染工具函數
 * 用於處理在服務器端和客戶端環境中都能正常工作的功能
 */

// 檢測是否在服務器端環境
export const isServer = typeof window === 'undefined';

/**
 * 獲取網站的基本 URL
 * 在服務器端使用硬編碼的值，在客戶端使用 window.location.origin
 * @returns {string} 網站的基本 URL
 */
export const getOrigin = () => {
  if (isServer) {
    // 在服務器端使用硬編碼的值
    return 'https://javplay.tv';
  }
  
  // 在客戶端使用 window.location.origin
  return window.location.origin;
};

/**
 * 安全的滾動到頁面頂部
 * 在服務器端不執行任何操作，在客戶端使用 window.scrollTo
 */
export const scrollToTop = () => {
  if (!isServer) {
    window.scrollTo(0, 0);
  }
};

/**
 * 安全的重新載入頁面
 * 在服務器端不執行任何操作，在客戶端使用 window.location.reload
 */
export const reloadPage = () => {
  if (!isServer) {
    window.location.reload();
  }
};
