import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  VideoDetailSkeleton, 
  HomeSkeleton, 
  VideoCardSkeleton,
  CategorySkeleton
} from '../SkeletonComponents';

const Loading = ({ type = 'default', count = 1 }) => {
  const { t } = useTranslation();
  
  // 根據不同的類型返回不同的骨架屏
  switch (type) {
    case 'videoDetail':
      return <VideoDetailSkeleton />;
    
    case 'home':
      return <HomeSkeleton />;
    
    case 'category':
      return <CategorySkeleton />;
    
    case 'videoCard':
      return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {Array(count).fill(0).map((_, index) => (
            <VideoCardSkeleton key={index} />
          ))}
        </div>
      );
    
    case 'default':
    default:
      return (
        <div className="flex flex-col items-center justify-center min-h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary-600 mb-4"></div>
          <p className="text-lg text-gray-600">{t('common.loading')}</p>
        </div>
      );
  }
};

export default Loading;
