import React, { useEffect, useRef } from 'react';

const VideoPlayer = ({ video }) => {
  const containerRef = useRef(null);
  const videoRef = useRef(null);
  
  // 預加載海報圖片
  useEffect(() => {
    if (video?.thumbnail_large) {
      const img = new Image();
      img.src = video.thumbnail_large;
      img.fetchPriority = 'high';
    }
  }, [video?.thumbnail_large]);
  
  useEffect(() => {
    if (!video || !containerRef.current) return;
    
    // 手動觸發DOMContentLoaded事件，讓外部腳本初始化播放器
    const initializeExternalScript = () => {
      console.log('Manually initializing external script');
      
      // 獲取所有播放器元素
      const players = document.querySelectorAll('.video-player');
      
      // 如果外部腳本已經定義了DOMContentLoaded事件處理函數，我們可以嘗試手動調用它
      if (players.length > 0) {
        console.log('Found video players:', players.length);
        
        // 模擬DOMContentLoaded事件
        const event = new Event('DOMContentLoaded');
        document.dispatchEvent(event);
        
        // 如果上面的方法不起作用，我們可以嘗試直接從外部腳本中提取關鍵代碼
        if (window.Plyr && window.Hls) {
          console.log('Plyr and Hls are available, initializing manually');
          
          players.forEach(container => {
            const video = container.querySelector('video');
            if (!video) return;
            
            // 初始化Plyr播放器
            const player = new window.Plyr(video, {
              controls: [
                'play-large', 'play', 'progress', 'current-time', 'mute', 
                'volume', 'fullscreen'
              ],
              fullscreen: { enabled: true, iosNative: true },
              resetOnEnd: true,
              hideControls: true,
              clickToPlay: true
            });
            
            // 監聽播放事件
            player.on('play', async () => {
              if (!video.src) {
                try {
                  const hid = container.dataset.hid;
                  console.log('Fetching m3u8 for HID:', hid);
                  
                  const response = await fetch(`https://av188.live/api/get_m3u8.php?hid=${hid}`);
                  const data = await response.json();
                  
                  if (data.url) {
                    console.log('Received m3u8 URL:', data.url);
                    
                    // 使用HLS.js加載視頻
                    if (window.Hls.isSupported()) {
                      const hls = new window.Hls();
                      hls.loadSource(data.url);
                      hls.attachMedia(video);
                      
                      hls.on(window.Hls.Events.MANIFEST_PARSED, () => {
                        console.log('HLS manifest parsed, starting playback');
                        video.play();
                      });
                    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                      // 對於iOS設備，使用原生HLS支持
                      video.src = data.url;
                      video.play();
                    }
                  }
                } catch (error) {
                  console.error('Error fetching m3u8:', error);
                }
              }
            });
          });
        }
      }
    };
    
    // 延遲一段時間後初始化，確保DOM已經完全渲染
    setTimeout(initializeExternalScript, 300);
    
  }, [video]);
  
  // 計算縮略圖URL (如果有)
  const thumbnailSmall = video?.thumbnail_small || 
                         (video?.thumbnail_large ? video.thumbnail_large.replace('.webp', '-small.webp') : '');
  
  return (
    <div className="video-container" ref={containerRef}>
      <div className="video-player-wrapper max-w-4xl mx-auto">
        <div className="video-player" data-hid={video?.hid || ''}>
          {/* 使用 preload="metadata" 來預加載視頻元數據但不加載整個視頻 */}
          <video 
            ref={videoRef}
            poster={video?.thumbnail_large || ''} 
            playsInline 
            controls
            preload="metadata"
            fetchpriority="high"
          >
            <source type="application/x-mpegURL" />
          </video>
          
          {/* 添加預加載的 LCP 圖片 (不可見，僅用於預加載) */}
          {video?.thumbnail_large && (
            <link 
              rel="preload" 
              href={video.thumbnail_large} 
              as="image" 
              type="image/webp" 
              fetchpriority="high"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
